// Theme for development/test environments

$gray-base:              #000 !default;
$gray-light:             lighten($gray-base, 46.7%) !default; // #777

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #E74C3C !default;
$navbar-inverse-border:                     lighten($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 #fff !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              darken($navbar-inverse-bg, 5%) !default;
$navbar-inverse-link-active-color:          #fff !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 5%) !default;
$navbar-inverse-link-disabled-color:        #ccc !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

@import 'node_modules/bootswatch/flatly/_variables';

// Core variables and mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";